import React from "react"
import MainNav from "../components/mainnav"
import { Link } from "gatsby"

const InPersonPage = () => (
  <div className="md:bg-white bg-white text-black pb-20">
    <div className="container max-w-full md:static sticky top-0 md:mb-20 mb-8 mx-auto text-center border-b-1 border-sand shadow-2xl">
      <MainNav />
    </div>
    <a id="Top"></a>
    <div>
      <div className="container mx-auto max-w-xl">
        <Link to="#Ceremony-Card">
          <img
            src="../../family-invitation.png"
            alt="Main"
            className="container object-contain max-w-xl mb-20 mx-auto shadow-2xl border-0 border-white rounded-lg"
          ></img>
        </Link>
      </div>
      {/* CEREMONY CARD */}
      <a id="Ceremony-Card">
        <div className="container overflow-auto bg-white mx-auto justify-center border-8 border-white rounded-lg shadow-2xl">
          <div className="container overflow-auto bg-white lg:flex mx-auto justify-center">
            <img
              src="../../dc-war-memorial.jpg"
              alt="Main"
              className="container object-contain max-w-xl lg:ml-5 mx-auto border-0 border-white rounded-lg justify-center"
            ></img>
            <div className="lg:ml-5 mx-auto">
              <p className="my-2 px-5 lg:text-left text-center md:text-6xl text-4xl font-serif">
                Ceremony Details
              </p>
              <p className="my-2 px-5 lg:text-left text-center mt-0 text-4xl lg:mb-0 mb-10">
                __
              </p>
              <p className="my-2 px-5 text-lg text-justify">
                We are really excited to have our marriage ceremony at the{" "}
                <Link
                  to="https://www.nps.gov/nama/planyourvisit/dc-war-memorial.htm"
                  className="text-ocean hover:underline"
                >
                  DC War Memorial
                </Link>
                . Please click{" "}
                <Link
                  to="https://goo.gl/maps/WhunK2YKhMspqnuG6"
                  className="text-ocean hover:underline"
                >
                  here for directions
                </Link>
                .
              </p>
              <p className="my-2 px-5 text-lg text-justify">
                This location is the perfect to say “I do” – the round, marble
                memorial is right on the National Mall, and is surrounded by
                beautiful trees. It is situated right in between the Reflecting
                Pool and Independence Avenue, near the World War II Memorial,
                and just across the street from the Martin Luther King, Jr
                Memorial. It’s the perfect balance of being close to all the
                hustle and bustle of the more popular monuments, while being a
                bit off the beaten path for some more privacy during our wedding
                ceremony.
              </p>
              <p className="my-2 px-5 text-lg text-justify">
                Because we have such a small group, the bride and groom will be
                reaching out to{" "}
                <strong className="font-semibold">
                  confirm your RSVP by October 1, 2020.
                </strong>{" "}
                We want to make sure everyone has a seat at the memorial and at
                our reception.
              </p>
            </div>
          </div>
          <p className="my-2 px-5 text-lg text-justify">
            Feel free to bring your own sanitizer, masks, and water (but alcohol
            is strictly prohibited on the premises). We have asked our local
            family attendees to please quarantine two weeks prior to this event.
            Please limit out of state travel, socializing, and being around
            others who you do not live with as much as possible. We want to
            ensure our event is safe during these uncertain times as much as we
            can. If for some reason this is not possible, please contact us.
          </p>
          <p className="my-2 px-5 lg:px-5 pr-5 text-lg text-justify">
            Please seek out a close family member if you are unsure of where to
            sit when you arrive at the memorial. The first row is reserved for
            parents of the bride and groom.
          </p>
          <p className="my-1 mt-6 text-xl text-center font-semibold font-serif italic">
            Dress Code
          </p>
          <p className="my-2 px-5 text-lg text-justify">
            We're hoping to have a really classy event and get lots of awesome
            pictures and video from our photographer and videographer. As such,
            we're requesting everyone attending in person adhere to a formal
            dress code.
          </p>
          <p className="my-1 mt-6 text-xl text-center font-semibold font-serif italic">
            Pictures & Video
          </p>
          <p className="my-2 px-5 text-lg text-justify">
            A photographer will be available during the ceremony and a
            videographer will be available before the ceremony, during, and at
            the reception. The bride and groom are meeting with the photographer
            to do first look pictures before the ceremony. Right after the
            ceremony, our whole group will take a picture together. Family
            pictures are scheduled for half an hour starting at 5:30 PM. If you
            want a special picture with the bride and groom, please be patient
            until the photographer has completed all family pictures first. We
            want a picture with you too!
          </p>
          <p className="my-1 mt-6 text-xl text-center font-semibold font-serif italic">
            Parking
          </p>
          <p className="my-2 px-5 text-lg text-justify">
            We recommend guests aim to arrive and start looking for parking no
            later than 4:30 PM due to the possibility of traffic on a Friday
            afternoon. Parking is tough around this area and we recommend using
            a service such as Uber and/or Lyft to get there. If you choose to
            drive, parking around the DC War Memorial is street parking which is
            a 5-10 minute walk. The closest parking for this event is metered
            spaces along Ohio Drive and the A, B, and C parking lots on Ohio
            Drive, which cost $2 per hour. For more information please visit{" "}
            <Link
              to="https://www.nps.gov/nama/planyourvisit/parking.htm"
              className="text-ocean hover:underline"
            >
              here for additional parking details
            </Link>
            .
          </p>
          <img
            src="../../memorial-parking-map.png"
            alt="Main"
            className="object-contain max-w-full mx-auto my-5 border-0 border-white rounded-lg justify-center"
          ></img>
        </div>
      </a>
      {/* RECEPTION CARD 1 */}
      <div className="container overflow-auto mb-20 my-10 mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
        <div className="mx-auto">
          <p className="text-center md:text-6xl text-4xl font-serif">
            Reception Details
          </p>
          <p className="text-center text-left text-4xl mb-10">__</p>
        </div>
        <div className="container overflow-auto lg:flex mx-auto items-center justify-center">
          <div className="lg:flex-1 pl-5 mt-2 mx-auto">
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              Our wedding reception will be held at Blackwall Hitch in Old Town
              Alexandria. This waterfront restaurant is where the bride and
              groom had their first date!
            </p>
            <p className="text-md text-center">__</p>
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              If you are not using Uber/Lyft to get to the reception, you can
              find metered street parking. The closest parking garage is located
              close to the restaurant at 220 N. Union Street.
            </p>
            <p className="text-md text-center">__</p>
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              We booked a private room that will seat all our guests and is
              completely isolated from the rest of the restaurant. This room
              will be ready at 6:30PM and is the first door on the left when you
              walk inside the restaurant’s double doors. You will find your name
              on the tables for assigned seating. They serve mostly American
              food and we have opted for a buffet style package. If you have any
              dietary restrictions or food allergies that we don’t know of,
              please let us know at least two weeks before the wedding so we can
              accommodate. Click{" "}
              <Link to="#Menu-Image" className="text-ocean hover:underline">
                here to scroll down to the full menu
              </Link>{" "}
              .
            </p>
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              There will be an open bar until a certain limit is reached. Once
              it has been reached, guests will be responsible for purchasing
              their own beverages. If you request a drink, please ask the
              waiter.
            </p>

            <p className="my-2 text-lg lg:px-5 pr-5 text-justify">
              Restrooms are right outside the private room. Make a left once you
              leave the room. These are NOT private restrooms, please wear your
              mask in public areas to protect yourself and others.
            </p>
            <p className="text-md text-center">__</p>
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              There is no "dance floor" but we will have music playing and we
              will do our first dance here before dinner starts.
            </p>
            <p className="text-md text-center">__</p>
            <p className="my-2 lg:px-5 pr-5 text-lg text-justify">
              The reception will conclude promptly at 10:00PM. Please don’t
              forget to sign our photo guestbook before you leave!
            </p>
          </div>

          <div className="container object-contain w-1/2 lg:mr-2 mx-auto lg:mt-0 mt-3 border-0 border-white rounded-lg justify-center">
            <img
              src="../../Alicia-15.jpg"
              alt="Main"
              className="container object-contain max-w-xl lg:mr-2 mx-auto border-0 border-white rounded-lg justify-center"
            ></img>
          </div>
        </div>
        <div className="lg:invisible lg:my-0 mt-5">
          <p className="text-md text-center">__</p>
        </div>
      </div>
    </div>
    <a id="Menu-Image">
      <img
        src="../../menu.png"
        alt="Main"
        className="container object-contain max-w-xl mx-auto shadow-2xl border-0 border-white rounded-lg justify-center"
      ></img>
    </a>
    <div className="text-center mt-10">
      <p className="text-4xl">__</p>
      <p className="text-xl mt-4">
        <Link to="#Top" className="text-ocean hover:underline">
          Back to Top
        </Link>
      </p>
    </div>
  </div>
)

export default InPersonPage
